import React, { useEffect, useState } from "react";

function Header({ setTheme, theme, showNavDropdown, setShowNavDropdown }) {
  const [activeAnchor, setActiveAnchor] = useState(null);

  useEffect(() => {
    const sectionIds = ["#home", "#about", "#projects", "#contact"];

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveAnchor(`#${entry.target.id}`);
        }
      });
    };

    // Create the Intersection Observer
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    });

    // Observe all sections
    sectionIds.forEach((sectionId) => {
      const section = document.querySelector(sectionId);
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      // Disconnect the observer on component unmount
      observer.disconnect();
    };
  }, []);

  return (
    <header
      className="fixed top-0 left-0 z-10 flex justify-center w-full h-auto p-8 m-auto text-base text-black dark:text-white drop-shadow-md"
      id="header"
    >
      <nav className="flex items-center justify-end w-full sm:justify-center">
        <ul
          className={
            (!showNavDropdown ? "h-0" : "h-36") +
            " overflow-hidden block sm:flex bg-slate-100 dark:bg-gray-700 dark:sm:bg-inherit sm:bg-inherit w-full sm:h-auto absolute gap-10 font-bold sm:w-fit sm:static sm:top-auto top-[80px] right-0 p-0"
          }
        >
          <li className="pt-4 ps-3 sm:pt-0 sm:ps-0">
            <a
              href="#home"
              className={activeAnchor === "#home" ? "active" : ""}
            >
              HOME
            </a>
          </li>
          <li className="ps-3 sm:ps-0">
            <a
              href="#about"
              className={activeAnchor === "#about" ? "active" : ""}
            >
              ABOUT
            </a>
          </li>
          <li className="ps-3 sm:ps-0">
            <a
              href="#projects"
              className={activeAnchor === "#projects" ? "active" : ""}
            >
              PROJECTS
            </a>
          </li>
          <li className="ps-3 sm:ps-0">
            {" "}
            <a
              href="#contact"
              className={activeAnchor === "#contact" ? "active" : ""}
            >
              CONTACT
            </a>
          </li>
          <li className="pb-1 ps-3 sm:ps-0 sm:pb-0">
            <button
              onClick={() => {
                theme === "dark" ? setTheme("light") : setTheme("dark");
              }}
            >
              THEME
            </button>
          </li>
        </ul>
        <i
          onClick={() => {
            setShowNavDropdown(!showNavDropdown);
          }}
          className="flex fa-solid fa-bars sm:hidden"
        ></i>
      </nav>
    </header>
  );
}

export default Header;
