export const projectData = [
  {
    title: "Arcana",
    titleIconSrc: "/arcana-logo.png",
    description:
      "A system for Customer Management, POS, Payment, and Inventory. It is a large system that is going to be used at scale by distributors.",
    projectTechs: ["React", "Material UI", "Sass"],
    // seeLiveLink: "https://g-trash-d8132.web.app/",
    imgSources: [
      "/arcana-website.png",
      "/arcana-website-2.png",
      "/arcana-website-3.png",
      "/arcana-website-4.png",
      "/arcana-website-5.png",
      "/arcana-website-6.png",
      "/arcana-website-7.png",
    ],
  },
  {
    title: "Cortico Landing Page",
    titleIconSrc: "/cortico-logo.svg",
    projectTechs: ["Nuxt", "Vue", "Tailwind"],
    seeLiveLink: "https://cortico.health/",
    description:
      "A landing page for a health tech startup called Cortico. It is a responsive website that is optimized for mobile and desktop.",
    imgSources: [
      "/cortico-website.png",
      "/cortico-website-2.png",
      "/cortico-website-3.png",
      // "/cortico-website-4.png",
      "/cortico-website-5.png",
      "/cortico-website-6.png",
      "/cortico-website-7.png",
      "/cortico-website-8.png",
      "/cortico-website-9.png",
    ],
  },
  {
    title: "Botica Plus +",
    titleIconSrc: "/botica-plus-plus-logo.svg",
    projectTechs: [
      "React",
      "Material UI",
      "Sass",
      "Express",
      "PostgreSQL",
      "Electron",
    ],
    description:
      "An inventory management system for a nationwide pharmacy called Botica Plus. It is an offline desktop application made with Electron. ",
    imgSources: [
      "/botica-plus-plus-app.png",
      "/botica-plus-plus-app-2.png",
      "/botica-plus-plus-app-3.png",
      "/botica-plus-plus-app-4.png",
      // "/botica-plus-plus-app-5.png",
      "/botica-plus-plus-app-6.png",
      "/botica-plus-plus-app-7.png",
      "/botica-plus-plus-app-8.png",
    ],
  },

  {
    title: "E.R.P Accounting",
    titleIconSrc: "/erp-logo.png",
    description:
      "Freelance system that I developed full stack for an accounting firm. They use it for client management, and billing generation",
    projectTechs: ["React", "Material UI", "Sass", "Express", "PostgreSQL"],
    imgSources: [
      "/erp-website.png",
      "/erp-website-2.png",
      "/erp-website-3.png",
      "/erp-website-4.png",
      "/erp-website-5.png",
    ],
  },
  {
    title: "Pokedex",
    titleIconSrc: "/pokedex.png",
    description:
      "A combination of two of things I love most, Pokemon and Javascript. It has filters, sort options, and search option. Each Pokemon also has a detailed page with their stats, description, etc.",
    projectTechs: ["React", "Tailwind", "Express"],
    seeLiveLink: "https://sldc-pokedex.netlify.app/",
    imgSources: [
      "/pokedex-website.png",
      "/pokedex-website-2.png",
      "/pokedex-website-3.png",
    ],
  },
  {
    title: "G-Trash",
    titleIconSrc: "/LogoGTRASH.png",
    description:
      "A part of our thesis G-Trash: A Reverse Vending machine. Admins, store owners, and users, each have separate access levels.",
    projectTechs: ["React", "Bootstrap", "Firebase"],
    seeLiveLink: "https://g-trash-d8132.web.app/",
    imgSources: [
      "/gtrash-website.png",
      "/gtrash-website-2.png",
      "/gtrash-website-3.png",
      "/gtrash-website-4.png",
    ],
    sampleLoginInfo: {
      accounts: ["admin@gmail.com", "user@gmail.com", "store@gmail.com"],
      password: "123456",
    },
  },
];
