import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

function Contact() {
  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [showEmailSubmitted, setShowEmailSubmitted] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAIL_PUBLIC_KEY
      )
      .then(
        (result) => {
          setNameValue("");
          setEmailValue("");
          setMessageValue("");
          setShowEmailSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <section
      className="relative flex items-center justify-center w-full p-10 lg:py-36 h-fit"
      id="contact"
    >
      <div className="flex flex-col items-start md:w-[56rem] gap-4 w-full">
        <h2 className="w-full text-3xl font-bold text-center text-blue-600 dark:text-blue-200 lg:text-left">
          CONTACT
        </h2>
        <div className="grid w-full gap-10 md:grid-cols-2">
          <form
            ref={form}
            onSubmit={sendEmail}
            className="flex flex-col justify-end w-full gap-2 contact-form"
          >
            <input
              className="p-2"
              type="text"
              name="user_name"
              placeholder="Name"
              onChange={(e) => {
                setNameValue(e.target.value);
              }}
              value={nameValue}
              required
            />
            <input
              className="p-2"
              type="email"
              name="user_email"
              placeholder="E-mail"
              onChange={(e) => {
                setEmailValue(e.target.value);
              }}
              value={emailValue}
              required
            />
            <textarea
              className="p-2"
              name="message"
              placeholder="Your message"
              rows={6}
              style={{ resize: "none" }}
              onChange={(e) => {
                setMessageValue(e.target.value);
              }}
              value={messageValue}
              required
            />
            <div className="flex justify-end w-full last-contact">
              <button
                className="w-1/3 p-2 transition-colors duration-300 border-2 border-gray-500 hover:bg-blue-500 hover:text-white hover:shadow-xl"
                type="submit"
                disabled={showEmailSubmitted}
              >
                {showEmailSubmitted === false ? "Submit" : "Email sent!"}
              </button>
            </div>
          </form>
          <div className="grid justify-center grid-rows-3 contact-icons">
            <div className="flex gap-4">
              <div
                className="flex items-center justify-center w-10 h-10 rounded-full shadow-2xl contact"
                title="HTML"
              >
                <i className="text-blue-600 fa-solid fa-map-location dark:text-blue-200"></i>
              </div>
              <span className="text-lg font-bold">
                Location
                <br />
                <span className="font-normal">Pampanga, Philippines</span>
              </span>
            </div>
            <div className="flex gap-4">
              <div
                className="flex items-center justify-center w-10 h-10 rounded-full shadow-2xl contact"
                title="HTML"
              >
                <i className="text-blue-600 fa-solid fa-envelope dark:text-blue-200"></i>
              </div>
              <span className="text-lg font-bold">
                Email
                <br />
                <span className="font-normal">delacruzsian22@gmail.com</span>
              </span>
            </div>
            <div className="flex gap-4">
              <div
                className="flex items-center justify-center w-10 h-10 rounded-full shadow-2xl contact"
                title="HTML"
              >
                <i className="text-blue-600 fa-brands fa-github dark:text-blue-200"></i>
              </div>
              <span className="text-lg font-bold">
                Github
                <br />
                <span className="font-normal">sian022</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
