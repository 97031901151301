import React from "react";

function Footer({ theme }) {
  return (
    <div className="flex items-center justify-center w-full footer">
      <footer className="block w-full">
        <div className="flex items-center justify-between max-w-[56rem] p-10 lg:py-6 lg:px-0 mx-auto">
          <span className="text-lg font-extrabold">
            Sian Dela Cruz &copy; 2023
          </span>
          <div className="flex gap-2">
            <a
              href="https://www.linkedin.com/in/sian-dela-cruz-011a6a26a/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="text-3xl fa-brands fa-linkedin"></i>
            </a>
            <a
              href="https://www.facebook.com/sian.delacruz"
              target="_blank"
              rel="noreferrer"
            >
              <i className="text-3xl fa-brands fa-square-facebook"></i>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
