import React from "react";
import moment from "moment";

function About() {
  return (
    <section
      className="relative flex items-center justify-center w-full h-auto p-10 lg:h-screen lg:p-0"
      id="about"
    >
      <div className="grid max-w-4xl grid-cols-2 gap-10 md:grid-cols-3">
        <div className="flex flex-col items-start justify-center col-span-2 gap-10">
          <h2 className="w-full text-3xl font-bold text-center text-blue-600 lg:text-left dark:text-blue-200">
            ABOUT ME
          </h2>
          <div className="flex flex-col items-center justify-center gap-3">
            <p className="text-lg text-center md:text-left">
              Approximately {moment("2022-04-01").fromNow()}, a friend of mine
              showed a website she created for a journalist society in her
              school. I got amazed and thought that it was really cool! "I can
              do something like this too, right?" It was then and there that my
              web development journey began. We have been studying programming
              since I was a freshman but this was the first time I took web
              development seriously. From there, I was hooked.{" "}
            </p>
            <p className="text-lg text-center md:text-left">
              For personal projects, I really like to build stuff with{" "}
              <span className="font-bold">React</span>,{" "}
              <span className="font-bold">Node.js</span>, and{" "}
              <span className="font-bold">Express</span>. Being able to create a
              full stack web application using only one programming language is
              really convenient. I also really like using{" "}
              <span className="font-bold">Tailwind</span> and{" "}
              <span className="font-bold">MUI</span> to speed up the CSS process
              without sacrificing quality. For the database, I have experience
              with both <span className="font-bold">MySQL</span> and{" "}
              <span className="font-bold">MongoDB</span>.
            </p>
            <p className="text-lg text-center md:text-left">
              While I'm not coding, I really love playing video games with my
              friends. I am especially into gacha games, and League nowadays. I
              also really like to play the guitar, sing, and dance a little bit.
              If you haven't tried any of these, I'd recommend them if you get
              the chance!
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center col-span-2 gap-10 p-3 md:gap-3 md:flex-col md:col-span-1">
          <div className="flex justify-end w-full">
            <img
              className="laptop-icon w-auto h-auto sm:w-[150px] sm:h-[150px]"
              src={process.env.PUBLIC_URL + "/laptop.png"}
              alt="laptop-icon"
            />
          </div>
          <div className="flex justify-start w-full">
            <img
              className="game-icon w-auto h-auto sm:w-[150px] sm:h-[150px]"
              src={process.env.PUBLIC_URL + "/video-game.png"}
              alt="game-icon"
            />
          </div>
          <div className="flex justify-end w-full">
            <img
              className="guitar-icon w-auto h-auto sm:w-[150px] sm:h-[150px]"
              src={process.env.PUBLIC_URL + "/electric-guitar.png"}
              alt="guitar-icon"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
