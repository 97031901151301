import React from "react";
import ProjectCard from "./ProjectCard";
import { projectData } from "../../data/ProjectData";

function Projects() {
  return (
    <section
      className="relative flex items-center justify-center w-full p-10 h-fit lg:py-36"
      id="projects"
    >
      <div className="flex flex-col justify-start max-w-4xl gap-10">
        <span className="w-full text-3xl font-bold text-center text-blue-600 dark:text-blue-200 lg:text-left">
          PORTFOLIO
        </span>

        <div className="grid h-auto grid-cols-1 grid-rows-2 gap-20 w-fit">
          {projectData.map((project, index) => (
            <ProjectCard
              key={project.title}
              {...project}
              reverse={index === 0 ? false : index % 2 === 1}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Projects;
