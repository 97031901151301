export const techOptions = [
  {
    label: "HTML",
    imgSrc: "/html-1.svg",
    heroInclude: false,
  },

  {
    label: "Javascript",
    imgSrc: "/logo-javascript.svg",
    heroInclude: false,
  },
  {
    label: "Typescript",
    imgSrc: "/typescript.svg",
    heroInclude: true,
  },
  {
    label: "React",
    imgSrc: "/react-2.svg",
    heroInclude: true,
  },
  {
    label: "CSS",
    imgSrc: "/css-3.svg",
    heroInclude: false,
  },
  {
    label: "Tailwind",
    imgSrc: "/tailwindcss.svg",
    heroInclude: true,
  },
  {
    label: "Material UI",
    imgSrc: "/mui-logo.png",
    heroInclude: true,
  },
  {
    label: "Sass",
    imgSrc: "/sass-logo.png",
    heroInclude: false,
  },
  {
    label: "Node.js",
    imgSrc: "/nodejs-1.svg",
    heroInclude: true,
  },
  {
    label: "Bootstrap",
    imgSrc: "/bootstrap-4.svg",
    heroInclude: false,
  },
  {
    label: "Firebase",
    imgSrc: "/firebase-1.svg",
    heroInclude: false,
  },

  {
    label: "MongoDB",
    imgSrc: "/mongodb-icon-1.svg",
    heroInclude: false,
  },
  {
    label: "MySQL",
    imgSrc: "/mysql-6.svg",
    heroInclude: false,
  },
  {
    label: "Nuxt",
    imgSrc: "/nuxt.svg",
    heroInclude: true,
  },
  {
    label: "Vue",
    imgSrc: "/vue.svg",
    heroInclude: true,
  },
  {
    label: "Electron",
    imgSrc: "/electron.svg",
    heroInclude: false,
  },
  {
    label: "PostgreSQL",
    imgSrc: "/postgresql.svg",
    heroInclude: true,
  },
  {
    label: "Express",
    imgSrc: "/express.svg",
    heroInclude: false,
  },
  {
    label: "And many more!",
    imgSrc: "/more.svg",
    heroInclude: true,
  },
];
