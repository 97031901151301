import React from "react";
import { techOptions } from "../../utils/TechOptions";

function Home() {
  const techOptionsToDisplay = techOptions.filter((tech) => tech.heroInclude);
  return (
    <section
      className="relative flex items-center justify-center w-full h-auto px-10 py-28 lg:p-0 lg:h-screen"
      id="home"
    >
      <div className="flex flex-col max-w-4xl gap-10">
        <div className="flex flex-col-reverse items-center justify-between gap-10 lg:flex-row">
          <div className="flex flex-col gap-4">
            <h1 className="text-6xl font-bold leading-tight text-center lg:text-left">
              Full Stack Web Developer
            </h1>
            <p className="text-2xl text-center ms-1 lg:text-left">
              Hi! I'm <span className="underline">Sian Dela Cruz</span>. I am a
              graduate of BS in Computer Engineering and I am a passionate full
              stack web developer from the Philippines.
            </p>
            <div className="flex justify-center gap-2 ms-1 lg:justify-normal">
              <a
                href="https://www.linkedin.com/in/sian-dela-cruz-011a6a26a/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="text-4xl fa-brands fa-linkedin"></i>
              </a>
              <a
                href="https://www.facebook.com/sian.delacruz"
                target="_blank"
                rel="noreferrer"
              >
                <i className="text-4xl fa-brands fa-square-facebook"></i>
              </a>
            </div>
          </div>
          <div className="lg:h-[300px] profile-img lg:w-[700px] h-[300px] w-[300px]"></div>
          {/* <img className='h-[300px] w-[300px] rounded-full outline outline-3 outline-black dark:outline-white profile-image glow-outline' src={process.env.PUBLIC_URL + '/sian-dp.jpg'} alt='sian-dp'/> */}
        </div>
        <div className="flex flex-col items-center justify-start gap-8 text-2xl lg:flex-row">
          <span className="font-semibold">Tech Stack | </span>

          <div className="flex flex-wrap justify-center gap-4 tech-stack-list">
            {techOptionsToDisplay.map((tech) => (
              <div
                key={tech.label}
                className="flex items-center justify-center rounded-full shadow-md"
                title={tech.label}
              >
                <img
                  className="w-1/2 h-1/2"
                  src={process.env.PUBLIC_URL + tech.imgSrc}
                  alt="tech-img"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
