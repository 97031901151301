import React, { useState } from "react";
import Slider from "react-slick";
import FsLightbox from "fslightbox-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../carousel.css";

const Carousel = ({ imgSources }) => {
  const [toggler, setToggler] = useState(false);
  // const [ lightboxSources, setLightboxSources ] = useState([])
  let lightboxSources = [];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div className="w-full h-auto lg:max-w-[35rem]">
      <Slider {...settings}>
        {imgSources.map((src, index) => {
          lightboxSources.push(src);
          return (
            <div
              key={index}
              className="w-full h-full cursor-pointer"
              onClick={() => {
                setToggler(!toggler);
              }}
            >
              <img
                className="rounded-lg shadow-lg"
                src={src}
                alt={"Slide " + index}
              />
            </div>
          );
        })}
      </Slider>

      <FsLightbox toggler={toggler} sources={lightboxSources} />
    </div>
  );
};

export default Carousel;
