import React from "react";
import Carousel from "../Carousel";
import { techOptions } from "../../utils/TechOptions";

export default function ProjectCard({
  title,
  titleIconSrc,
  description,
  projectTechs,
  seeLiveLink,
  imgSources,
  reverse,
  sampleLoginInfo,
}) {
  return (
    <div
      className={
        reverse
          ? "flex flex-col-reverse items-center justify-center w-full px-6 py-3 lg:py-0 shadow-md lg:flex-row-reverse rounded-2xl project-card"
          : "flex flex-col-reverse items-center justify-center w-full px-6 py-3 lg:py-0 shadow-md lg:flex-row rounded-2xl project-card"
      }
    >
      <div className="flex flex-col items-center h-auto gap-4 m-10 text-center w-auto md:w-[50rem]">
        <div className="flex items-center">
          <span className="text-xl font-bold uppercase">{title}</span>

          <img
            className="inline-block w-12"
            src={process.env.PUBLIC_URL + titleIconSrc}
            alt="title-icon"
          />
        </div>

        <p className="text-lg">{description}</p>

        <div className="flex gap-2 project-tech lg:max-w-[200px] lg:flex-wrap justify-center">
          {projectTechs.map((tech, index) => (
            <div
              key={`tech-${index}`}
              className="flex items-center justify-center w-10 h-10 rounded-full shadow-2xl"
              title={tech}
            >
              <img
                className="w-1/2 h-1/2"
                src={
                  process.env.PUBLIC_URL +
                  (techOptions.find((item) => item.label === tech)?.imgSrc ||
                    "/dev-icon.png")
                }
                alt="tech-icon"
              />
            </div>
          ))}
        </div>

        {sampleLoginInfo && (
          <div className="block leading-tight">
            <span className="font-bold">Sample</span> <br />
            {sampleLoginInfo.accounts.map((account) => (
              <span key={account}>
                {account} <br />
              </span>
            ))}
            <span>Password: {sampleLoginInfo.password}</span>
          </div>
        )}

        {seeLiveLink && (
          <button className="w-2/3 p-3 font-bold text-whiterounded-lg hover:text-blue-500">
            <a
              className="flex items-center justify-center gap-2"
              href={seeLiveLink}
              target="_blank"
              rel="noreferrer"
            >
              See Live
              <i className="fa-solid fa-arrow-up-right-from-square link-icon"></i>
            </a>
          </button>
        )}
      </div>

      <Carousel
        imgSources={imgSources.map((src) => process.env.PUBLIC_URL + src)}
      />
    </div>
  );
}
